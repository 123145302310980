const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://afavkoj2q8.execute-api.us-west-2.amazonaws.com/prod',
    TEAM_API_URL: 'https://h79ukjghni.execute-api.us-west-2.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'master',
    HOSTNAME: 'https://teams-service.testing.recovery.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.testing.recovery.forwoodsafety.com',
    WEBSOCKET: 'wss://cfdwjjzvvj.execute-api.us-west-2.amazonaws.com/testing'
  },
};

export default config;
